import React from 'react';
import styled from 'styled-components';
import { colors } from '../styles/theme';
import { RadioButton } from '../styles/shared';

const RadioToggle = ({ current, left, right, handleClick }) => {
  return (
    <Container>
      <label
        aria-current={current === left.value}
        onClick={() => handleClick(left.value)}
      >
        <input type='radio' name='toggle' />
        <RadioButton>
          <div className='dot' />
        </RadioButton>
        {left.title}
      </label>
      <label
        aria-current={current === right.value}
        onClick={() => handleClick(right.value)}
      >
        <input type='radio' name='toggle' />
        <RadioButton />
        {right.title}
      </label>
    </Container>
  );
};
const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1.5rem;
  position: relative;

  input[type='radio'] {
    // hide browser built in radio buttons
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  label {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 0.875rem;
    align-items: center;
    cursor: pointer;

    &[aria-current='true'] {
      cursor: default;

      button:after {
        background-color: ${colors.blue};
      }
    }
  }
`;

export default RadioToggle;
