import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { colors, devices, fontWeights } from '../styles/theme';
import {
  BuildLink,
  Intent,
  LearnMoreLink,
  OptionInfo,
  StartingOption,
  StartingOptions,
  TextBlock,
} from '../styles/shared';
import { floorPlansPageContent, unitDataConfig } from '../utils/dataConfig';
import RadioToggle from '../components/RadioToggle';
import Modal from '../components/Modal';
import ModalImage from '../components/modals/ModalImage';
import { storeReferralData } from '../utils/helpers';
import SEO from '../components/SEO';
import { useDispatch, useSelector } from 'react-redux';
import { getLoadedAssets, setLoadedAsset } from '../store/build';

const unitCommunityLotMap = {
  m4a: { community: 'mountain', lot: 'garage' },
  m4b: { community: 'mountain', lot: 'forest' },
  m6a: { community: 'mountain', lot: 'forest' },
  m6b: { community: 'mountain', lot: 'forest' },
  m10a: { community: 'mountain', lot: 'trails' },
  m16a: { community: 'mountain', lot: 'garage' },
  cyber: { community: 'mountain', lot: 'garage' },
};

const FloorPlansPage = ({ data, location }) => {
  // store referral params
  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      storeReferralData(params);
    }
  }, []);
  const heroImage = data.allFile.nodes.find(
    image => image.name === 'floor-plans-hero'
  );
  const demoImage = data.allFile.nodes.find(
    image => image.name === 'floor-plans-demo'
  );
  const unitImages = data.units.nodes;
  const featureImages = data.allFile.nodes
    .filter(({ name }) => name.startsWith('floor-plans-feature-'))
    .sort((a, b) => (a.name > b.name ? 1 : -1));

  const [intents, setIntents] = useState({});
  const changeIntent = (unit, intent) =>
    setIntents(prev => ({
      ...prev,
      [unit]: intent,
    }));

  const [unitModal, setUnitModal] = useState(null);
  const handleImageClick = unit => setUnitModal(unit);
  const dispatch = useDispatch();
  const loadedAssets = useSelector(getLoadedAssets);
  const handleLoad = unitType => {
    !loadedAssets[unitType] && dispatch(setLoadedAsset(unitType));
  };
  return (
    <>
      <SEO title='Floor Plans' />
      <Layout>
        <Container>
          <HeroImageWithText>
            <GatsbyImage
              alt='a complex modular building'
              image={heroImage.childImageSharp.gatsbyImageData}
              style={{
                gridArea: '1 / 1',
                borderRadius: '0.375rem',
              }}
            />
            <div className='overlay' />
            <div className='text'>
              <h1>{floorPlansPageContent.hero.heading}</h1>
              <p>{floorPlansPageContent.hero.paragraph}</p>
              <a href='#list'>Start Building</a>
            </div>
          </HeroImageWithText>
          <StartingOptions id='list'>
            {unitDataConfig.options.list.map(unit => {
              const unitInfo = unitDataConfig.options[unit];
              return (
                <StartingOption key={unit}>
                  <ZoomButton onClick={() => handleImageClick(unit)}>
                    <GatsbyImage
                      image={
                        unitImages.find(
                          image => image.name === `unit-${unit}-1`
                        ).childImageSharp.gatsbyImageData
                      }
                      alt={`${unitInfo.title} unit type`}
                      onLoad={() => handleLoad(unit)}
                    />
                  </ZoomButton>
                  <OptionInfo>
                    <div className='content'>
                      <h2>{unitInfo.title}</h2>
                      <div>
                        <p>{unitInfo.description}</p>
                        <ul>
                          {unitInfo.features.map(feature => (
                            <li key={feature}>&bull; {feature}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className='actions'>
                      <Intent>
                        <h3>Start building as:</h3>
                        <RadioToggle
                          current={intents[unit] || 'own'}
                          left={{ value: 'own', title: 'owner' }}
                          right={{ value: 'rent', title: 'renter' }}
                          handleClick={intent => changeIntent(unit, intent)}
                        />
                        <LearnMoreLink to='/own-vs-rent/'>
                          learn more
                        </LearnMoreLink>
                      </Intent>
                      <BuildLink
                        to={`/builder/?src=floorPlans&c=${
                          unitCommunityLotMap[unit].community
                        }&l=${unitCommunityLotMap[unit].lot}&u=${unit}&i=${
                          intents[unit] || 'own'
                        }`}
                      >
                        Build
                      </BuildLink>
                    </div>
                  </OptionInfo>
                </StartingOption>
              );
            })}
          </StartingOptions>
          <BuilderDemoSection>
            <div className='text-block'>
              <h1>{floorPlansPageContent.builderDemo.heading}</h1>
              <h2>{floorPlansPageContent.builderDemo.subheading}</h2>
              <p>{floorPlansPageContent.builderDemo.paragraph}</p>
            </div>
            <aside className='media'>
              <GatsbyImage
                alt='builder demo'
                image={demoImage.childImageSharp.gatsbyImageData}
              />
            </aside>
          </BuilderDemoSection>
          <FeaturesContainer>
            <TextBlock>
              <h1>{floorPlansPageContent.features.heading}</h1>
              <p>{floorPlansPageContent.features.paragraph}</p>
            </TextBlock>
            <Features>
              {floorPlansPageContent.features.list.map((feature, index) => (
                <Feature key={feature.heading}>
                  <GatsbyImage
                    alt={feature + ' icon'}
                    image={featureImages[index].childImageSharp.gatsbyImageData}
                    objectFit='contain'
                  />
                  <h2>{feature.heading}</h2>
                  <p>{feature.paragraph}</p>
                </Feature>
              ))}
            </Features>
          </FeaturesContainer>

          <CallToAction>
            <h3>{floorPlansPageContent.callToAction}</h3>
            <Link to='/builder/'>Start Building</Link>
          </CallToAction>
        </Container>
        {unitModal && (
          <Modal
            anchorStyle={{
              width: '100%',
              height: '100%',
            }}
            handleClose={() => setUnitModal(null)}
          >
            <ModalImage
              image={unitImages.find(
                image => image.name === `unit-${unitModal}-1`
              )}
              alt={`${unitDataConfig.options[unitModal].title} full screen`}
              handleClose={() => setUnitModal(null)}
            />
          </Modal>
        )}
      </Layout>
    </>
  );
};

const Container = styled.section`
  padding: calc(var(--body-padding-y) / 3) var(--body-padding-x);
  display: grid;
  row-gap: 4rem;
`;
const HeroImageWithText = styled.div`
  display: grid;
  min-height: 35rem;

  .overlay {
    grid-area: 1 / 1;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 0.375rem;
    background: linear-gradient(
      to top,
      ${colors.darkTransparent},
      transparent 70%
    );
  }

  .text {
    grid-area: 1 / 1;
    z-index: 2;
    color: ${colors.white};
    justify-self: start;
    align-self: end;
    margin: 6% 4%;
    max-width: 34rem;
    // refer to shared/TextBlock
    display: grid;
    row-gap: 1.5rem;
    h1 {
      font-weight: ${fontWeights.medium};
      font-size: 4rem;
      line-height: 4.5rem;
      filter: drop-shadow(0 1px 4px ${colors.darkTransparent});
    }

    p {
      font-size: 1rem;
      line-height: 2rem;
    }

    a {
      font-weight: ${fontWeights.medium};
      height: 3.5rem;
      border-radius: 0.375rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${colors.blue};
      color: ${colors.white};

      :hover {
        filter: brightness(1.5);
      }
    }

    @media (${devices.s}) {
      row-gap: 1rem;
      h1 {
        font-size: 3.4rem;
        line-height: 5.1rem;
      }
    }
    @media (${devices.xs}) {
      row-gap: 0.75rem;
      h1 {
        font-size: 2.6rem;
        line-height: 3.5rem;
      }
    }
  }
`;
const FeaturesContainer = styled.section`
  padding-top: var(--section-padding-y);
  display: grid;
  grid-template-columns: 1.6fr 1fr;
  row-gap: var(--text-block-padding-y);
`;
const Features = styled.div`
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  > * {
    border-right: 1px solid ${colors.black};
    :nth-child(3n) {
      border-right: unset;
    }
    :nth-child(-n + 3) {
      border-bottom: 1px solid ${colors.black};
    }
  }
  @media (${devices.s}) {
    grid-template-columns: 1fr 1fr;
    > * {
      :nth-child(3n) {
        border-right: 1px solid ${colors.black};
      }
      :nth-child(2n) {
        border-right: unset;
      }
      :nth-child(-n + 4) {
        border-bottom: 1px solid ${colors.black};
      }
    }
  }
  @media (${devices.xs}) {
    grid-template-columns: 1fr;
    > :nth-child(-n + 6) {
      border-right: unset;
      border-bottom: unset;
    }
  }
`;
const Feature = styled.div`
  display: grid;
  justify-items: center;
  row-gap: 1rem;
  padding: 3rem 2rem;
  > :first-child {
    width: 4rem;
    height: 4rem;
  }
  h2 {
    font-weight: ${fontWeights.medium};
    margin-top: 1rem;
  }
  p {
    text-align: center;
  }
`;
const CallToAction = styled.section`
  display: grid;
  row-gap: 2rem;
  justify-items: center;
  padding: 4rem 0;
  h3 {
    font-weight: ${fontWeights.medium};
    font-size: 1.5rem;
    line-height: 3rem;
    white-space: pre-wrap;
    text-align: center;
  }
  a {
    font-weight: ${fontWeights.medium};
    width: 12rem;
    height: 3.5rem;
    border-radius: 0.375rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.blue};
    color: ${colors.white};

    :hover {
      filter: brightness(1.5);
    }
  }
`;
const ZoomButton = styled.button`
  grid-area: 1 / 1;
  cursor: zoom-in;
  border-radius: 0.375rem;
  border: 1px solid ${colors.darkGray};
`;
const BuilderDemoSection = styled.section`
  margin-top: var(--text-block-padding-y);
  padding: var(--text-block-padding-y) 0;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  .text-block {
    display: grid;
    row-gap: var(--heading-paragraph-row-gap);
    padding-right: var(--text-block-padding-right);
    h1 {
      font-weight: ${fontWeights.bold};
      font-size: 4rem;
      line-height: 4.5rem;
    }
    h2 {
      font-weight: ${fontWeights.bold};
      font-size: 1.375rem;
      line-height: 2.125rem;
    }
    p {
      font-size: 1rem;
      line-height: 2rem;
      white-space: pre-wrap;
    }
    a {
      margin-top: 2rem;
    }

    @media (${devices.s}) {
      h1 {
        font-size: 3.4rem;
        line-height: 5.1rem;
      }

      a {
        margin-top: 1rem;
        margin-right: -25%;
        justify-self: center;
      }
    }
    @media (${devices.xs}) {
      h1 {
        font-size: 2.6rem;
        line-height: 3.5rem;
      }
      a {
        margin-right: 0;
        width: 100%;
        background-color: ${colors.blue};
        color: ${colors.white};
        border-color: transparent;
      }
    }
  }
  .media {
    display: grid;
    align-items: center;
    @media (${devices.s}) {
      order: -1;
      grid-column: 1 / -1;
      margin: 2rem;
      border-radius: 0.375rem;
    }
  }
`;
export default FloorPlansPage;

export const pageQuery = graphql`
  query getFloorPlansImages {
    allFile(filter: { name: { regex: "/^floor-plans/" } }) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(quality: 70, width: 1440, placeholder: BLURRED)
        }
      }
    }
    units: allFile(filter: { name: { regex: "/^unit/" } }) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(quality: 70, height: 1080, placeholder: NONE)
        }
      }
    }
  }
`;
