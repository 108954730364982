import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styles/theme';
import { GatsbyImage } from 'gatsby-plugin-image';
import {
  CloseButton,
  fadeIn,
  TransparentBackgroundModalContainer,
} from '../../styles/shared';
import { IoMdCloseCircle } from '@react-icons/all-files/io/IoMdCloseCircle';

const ModalImage = ({ image, images, alt, handleClose }) => {
  const handleClick = e => {
    // filter out click events on image
    e.stopPropagation();
    e.target.tagName !== 'IMG' && handleClose();
  };
  return (
    <TransparentBackgroundModalContainer onClick={handleClick}>
      <CloseButton
        color={colors.white}
        size='2.5rem'
        alt='close button'
        onClick={handleClose}
      />
      {image ? (
        <Image
          image={image.childImageSharp.gatsbyImageData}
          alt={alt}
          objectFit='contain'
          style={{ gridArea: '1 / 1', height: '100%', width: '100%' }}
        />
      ) : (
        <>
          <Image
            image={images[0].childImageSharp.gatsbyImageData}
            alt={alt}
            objectFit='contain'
            style={{
              gridArea: '1 / 1',
              height: '100%',
              width: '100%',
              zIndex: 0,
            }}
          />
          {images[1] && (
            <Image
              image={images[1].childImageSharp.gatsbyImageData}
              alt={alt}
              objectFit='contain'
              style={{
                gridArea: '1 / 1',
                height: '100%',
                width: '100%',
                zIndex: 1,
              }}
            />
          )}
        </>
      )}
    </TransparentBackgroundModalContainer>
  );
};

const Image = styled(GatsbyImage)`
  grid-area: 1 / 1;
  height: 100%;
  width: 100%;
  animation: 0.3s ${fadeIn} ease-out;
`;
export default ModalImage;
